const jmsm = {
    title: 'Je mise sur moi',
    description:
        'Empower your teen with Je mise sur moi. Discover academic and career guidance done differently.',
    tagline1: '“ What do you want to do with your life\u00A0? ”',
    tagline2: 'A question you have asked your teen, but that is yet to be answered.',
    signUp: 'Register now',
    previewCardTitle: 'Our first product',
    previewCardDescription:
        'Empower your teen with <1>{{value}}</1>. Discover academic and career guidance done differently.',
    whyJMSMCardTitle: 'Why <1>{{value}}</1>\u00A0?',
    whyJMSMCardDescription:
        'A tool that guides your teen in his academic and career guidance reflection, helps him define his aspirations and motivates him to go forward confident of his choices.',
    winkCardTitle: 'In a glimpse',
    winkCardDescription:
        'One application. One journey. 4h of videos. 12h of situational and reflection activities. 16h of preparation towards the transition to higher education and/or the working world.',
    participantsCardTitle: 'Promotes the desire to go further',
    participantsCardDescription:
        'Yesterday, they experienced the same reality as your teen. Today, they are forward-looking. 16 young professionals and entrepreneurs with successful track records share their thoughts on camera in order to accompany your teen in every step of his reflection.',
    stAnneTitle: 'Adopted by Collège Sainte-Anne',
    stAnneDescription: 'Offered to 800 students to better prepare them for the world ahead.',
    ministerCardTitle: 'Aligned to the ministerial requirements',
    ministerCardDescription:
        'A tool that surpasses the pedagogical intentions of the Academic and Career Guidance Contents established by the Ministry of Education of Québec for the 12 to 18 year olds.',
    testimonies: {
        testimony1: '"A tool that answers a true issue and that I recommend !”',
        testimony2:
            '“ Making decisions that reflect who you are, that’s what Je mise sur moi is all about ! ”',
        testimony3:
            '“ Very well executed, visually pleasing, intuitive, beautiful and coherent sequences. ”',
        author1: 'Véronique Boulet',
        author2: 'Nadia Slimani',
        author3: 'Jennifer Gouveia',
        job1: 'Director secondary 5, Collège Sainte-Anne',
        job2: 'Director secondary 4, Collège Sainte-Anne',
        job3: 'Guidance Counsellor',
    },
    discoverHow: 'Discover how 800+ teens are investing in their future',
    takeFutureTitle: 'Shaping your future',
    takeFutureDescription:
        'Situational activities, introspections and content that engages creativity. These will help your teen identify his values, interests, aptitudes and aspirations. Learn about yourself in order to make decisions that reflect you and motivate you to pursue your education.',
    tomorrowTitle: 'Committing today to succeed tomorrow',
    tomorrowDescription:
        'Nurtures his desire to undertake projects, to develop new skills and to validate his perceptions. Your teen will identify achievable objectives and develop action plans, the first steps in the journey to reach his full potential.',
    makeChoicesTitle: 'Making informed choices',
    makeChoicesDescription:
        'Your teen will elaborate action plans to validate and distinguish reality from his perceptions and to follow through with his questions. A wide range of factors to be considered and propositions of outlined solutions will allow your teen to make informed decisions that are in harmony with him/her.',
    orientationTitle: 'Guidance done differently',
    orientationDescription:
        'A virtual environment for personal and intellectual growth that speaks to your teen. A self-directed approach that considers all the key factors in the guidance process in 16 hours. Accessible anytime, anywhere.',
    wellSurroundedTitle: 'Surrounding yourself with positivity is key',
    wellSurroundedDescription:
        'Situational activities, scenarios to spark discussions and the identification of key individuals in the achievement of his objectives will enlighten him to the importance of adopting a healthy communication approach online and offline and to cultivate his network.',
    haveDiscussionTitle: 'Initiating forward-looking discussions',
    haveDiscussionDescription:
        'Promotes constructive discussions with your teen, at his own pace, through the complementary cards. Useful to evoque new ways to reflect upon and to encourage him/her.',
    supportedBy: 'Achieved with their support',
    supportOnLaRuche: 'Support us on La Ruche !',
    supportOnLaRucheParagraph:
        'Our crowdfunding campaign is launched. Get your presale right to access to Je mise sur moi to support us.',
    schoolRepTitle: 'School representative\u00A0?',
    schoolRepDescription:
        'Interested in better preparing your students for the world that awaits them ? Deploy Je mise sur moi in your school today !',
    moreInfoTitle: 'Need more information\u00A0?',
    moreInfoDescription: 'To learn more about Je mise sur moi, visit our boutique.',
    stayConnected: "Let's keep in touch",
    emailDescription:
        'Register here to be informed about the progress of our team, our new virtual products, the availability of the English version, etc.',
    informButton: 'Let me know',
}

export default jmsm
