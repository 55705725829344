const about = {
    title: 'About',
    header: 'About',
    description:
        'Specialized in educational technologies, we imagine, create and propel educational content to accompany the 12 to 21 year olds in reaching their full potential.',
    previewCardDescription:
        'We strive for a world where everyone is fulfilled and happy. We focus on education and innovation as drivers for change.',
    missionCardTitle: 'Mission',
    missionCardDescription:
        'Imagine, create and propel digital educational content to accompany the 12 to 21 year olds in reaching their full potential.',
    visionCardTitle: 'Vision',
    visionCardDescription: 'Achieving fulfillment, together.',
    employeeGridTitle: 'We are PA',
    cofounder: 'Cofounder',
    developer: 'Developer extraordinaire',
    digitalMagician: 'Digital magician',
    momentCatcher: 'Moment_catcher.mp4',
    graphicCreator: 'Graphic creator',
    maestro: 'Maestro',
    contributors: 'Pro bono contributors',
}

export default about
