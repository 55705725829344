const about = {
    title: 'À propos',
    header: 'À propos',
    description:
        "Spécialisé en technologies éducatives, nous créons et propulsons des contenus éducationnels pour accompagner les jeunes dans l'atteinte de leur plein potentiel.",
    previewCardDescription:
        'Nous aspirons à un monde où chacun est épanoui et heureux. Nous misons sur l’éducation et l’innovation comme moteurs de changement.',
    missionCardTitle: 'Mission',
    missionCardDescription:
        'Imaginer, créer et propulser des contenus éducationnels virtuels pour accompagner les 12 à 21 ans dans l’atteinte de leur plein potentiel.',
    visionCardTitle: 'Vision',
    visionCardDescription: 'S’accomplir, ensemble.',
    employeeGridTitle: 'Nous sommes PA',
    cofounder: 'Cofondateur',
    developer: 'Développeur extraordinaire',
    digitalMagician: 'Magicienne numérique',
    momentCatcher: 'Capteur_de_moments.mp4',
    graphicCreator: 'Créatrice graphique',
    maestro: 'Maestro',
    contributors: 'Contributeurs pro bono',
}

export default about
