const misc = {
    email: 'Adresse courriel',
    watch: 'Visionner',
    siteBy: 'Site Web par',
    learnMore: 'Apprenez en plus',
    letsTalk: 'Parlons',
    comingSoon: 'Bientôt disponible',
    logoGridTitle: 'Ils nous supportent',
    ourClients: 'Nos clients',
    awardGridTitle: 'Distinctions',
    send: 'Envoyer',
    discover: 'Découvrir',
    presale: 'Prévente',
    shop: 'Boutique',
}

export default misc
