const misc = {
    email: 'Email',
    watch: 'Watch now',
    siteBy: 'Website by',
    learnMore: 'Learn more',
    letsTalk: "Let's talk",
    comingSoon: 'Coming soon',
    logoGridTitle: 'They support us',
    ourClients: 'Our clients',
    awardGridTitle: 'Distinctions',
    send: 'Send',
    discover: 'Discover',
    presale: 'Presale',
    shop: 'Shop',
}

export default misc
