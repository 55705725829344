const contribution = {
    title: 'Our contribution',
    header: 'Our contribution to the collective',
    description:
        "We have a dream, let's contribute to ensuring that all have access to education. Together, let's create a world where everyone can aspire to achieve their goals, where everyone cultivates their passions while being on their X.",
    ourDream: 'Our dream',
    ourDreamDescription:
        "We have a dream, let's contribute to ensuring that all have access to education. Together, let's create a world where everyone can aspire to achieve their goals, where everyone cultivates their passions while being on their X.",
    reality: 'How to make it reality',
    realityDescription:
        'We are donating some of our products and services to various non-profit organizations in our community. We realize awareness campaigns to support causes that are close to our hearts. We offer a variety of free educational contents. We are open to collaborate with individuals and organizations who share our dream in order to realize impactful initiatives that will accompany our next generation in reaching their full potential.',
    contributions: 'Our contributions as of today',
    dontDrink: "Don't drink and drive awareness campaign",
    antreJeune: "L'Antre-Jeunes de Mercier Est",
    antreJeuneDescription:
        'We donated our product « Je mise sur moi » to all the teenagers supported by L’Antre-Jeunes de Mercier Est. Among other things, the organization offers to teenagers from disadvantaged backgrounds access to their two establishments where they can participate in various enrichment activities accompanied by social workers and facilitators, access various equipment and participate in events and learning opportunities generously offered by their team.',
    harmony: 'Projet Harmonie',
    harmonyDescription:
        'We donated our product "Je mise sur moi" to all the teenagers supported by the Harmony Project. The organization\'s mission is to create a welcoming living environment integrated into the community for the residents of the HLM La Pépinière (Non-profit housing program - HLM) in order to support them in the development of their full potential and has the vision of making their living environment a model of inspiration for all by promoting personal and collective development.',
    support: 'Support us',
    supportDescription:
        'To help us in our community initiatives, to allow us to create more contents for our free educational repertoire, to give us the necessary resources to realize high-impact awareness campaigns or to encourage us to continue to donate our products and services to various non-profit organizations, we encourage you to subscribe to our Patreon where all the funds raised allow us to continue all these initiatives.',
    supportButton: 'Support Extrapole',
}

export default contribution
