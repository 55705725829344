import homepage from './homepage'
import contact from './contact'
import about from './about'
import error404 from './error404'
import jmsm from './jmsm'
import misc from './misc'
import thankYou from './thankYou'
import socialMedia from './socialMedia'
import contribution from './contribution'

const en = {
    homepage,
    about,
    contact,
    error404,
    jmsm,
    misc,
    thankYou,
    socialMedia,
    contribution,
}

export default en
