const thankYou = {
    title: 'Thank you !',
    header: 'Thank you !',
    description: 'We will get back to you soon.',
    sent: 'It’s sent !',
    contactingUs:
        'Thank you for contacting us. We will take a look at your request and we will get back to you shortly.',
}

export default thankYou
