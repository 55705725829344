import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../EN/en'
import fr from '../FR/fr'

// the translations
// (tip move them in a JSON file and import them)
const resources = { en, fr }

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',

        keySeparator: '.', // keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
            // eslint-disable-next-line no-unused-vars
            format(value, format, lng) {
                if (format === 'uppercase') return value.toUpperCase()
                if (format === 'lowercase') return value.toLowerCase()
                return value
            },
        },
    })

export default i18n
