const jmsm = {
    title: 'Je mise sur moi',
    description:
        'Outillez votre ado pour qu’il puisse faire des choix éclairés. Découvrez Je mise sur moi, l’orientation scolaire et professionnelle, autrement.',
    tagline1: '« Qu’est-ce que tu veux faire dans la vie\u00A0? »',
    tagline2: 'Une question que vous avez posée à votre ado, mais qui est encore sans réponse.',
    signUp: 'Inscrivez-vous',
    previewCardTitle: 'Notre premier produit',
    previewCardDescription:
        'Outillez votre ado pour qu’il puisse faire des choix éclairés. Découvrez <1>{{value}}</1>, l’orientation scolaire et professionnelle, autrement.',
    whyJMSMCardTitle: 'Pourquoi <1>{{value}}</1>\u00A0?',
    whyJMSMCardDescription:
        "Un outil dynamique qui guide votre ado dans sa démarche d'orientation, l'aide à définir ses aspirations et le motive à aller de l'avant confiant de ses choix.",
    winkCardTitle: 'En un clin d’œil,',
    winkCardDescription:
        'Une application. Un parcours. 4h de vidéos. 12h d’activités de réflexion et mises en situation. 16h de préparation à la transition vers les études supérieures et/ou le monde du travail.',
    participantsCardTitle: 'Donner l’envie d’aller plus loin',
    participantsCardDescription:
        'Hier, ils vivaient la même réalité que votre ado. Aujourd’hui, ils voient grand. 16 jeunes professionnels et entrepreneurs au parcours de réussite partagent leurs réflexions à la caméra afin de l’accompagner dans chacune des étapes de sa démarche.',
    stAnneTitle: 'Adopté par le Collège Sainte-Anne',
    stAnneDescription: 'Offert à 800 élèves pour mieux les préparer au monde qui les attend.',
    ministerCardTitle: 'Aligné aux exigences ministérielles',
    ministerCardDescription:
        'Un outil qui surpasse les intentions pédagogiques des Contenus en Orientation Scolaire et Professionnelle établis par le ministère de l’Éducation du Québec pour les 12 à 18 ans.',
    testimonies: {
        testimony1: '« Un outil qui répond à un vrai enjeu et que je recommande ! »',
        testimony2: '« Prendre des décisions à son image, c’est ça Je mise sur moi ! »',
        testimony3:
            '« Très bien réalisée, agréable visuellement, intuitif, de belles séquences cohérentes. »',
        author1: 'Véronique Boulet',
        author2: 'Nadia Slimani',
        author3: 'Jennifer Gouveia',
        job1: 'Directrice Secondaire 5, Collège Sainte-Anne',
        job2: 'Directrice Secondaire 4, Collège Sainte-Anne',
        job3: 'Conseillère d’orientation',
    },
    discoverHow: 'Découvrez comment 800+ ados investissent dans leur futur',
    takeFutureTitle: 'Prendre son avenir en main',
    takeFutureDescription:
        'Des mises en situation, des appels à l’imaginaire et des introspections l’aideront à cerner ses valeurs, ses intérêts, ses aptitudes et ses aspirations. Mieux se connaître pour prendre des décisions à son image et favoriser la persévérance scolaire.',
    tomorrowTitle: 'S’investir aujourd’hui pour réussir demain',
    tomorrowDescription:
        'Aviver son désir d’entreprendre, de développer de nouvelles compétences et de valider ses perceptions. Votre ado identifiera des objectifs réalisables et élaborera des plans d’action, les premiers pas dans l’atteinte de son plein potentiel.',
    makeChoicesTitle: 'Faire des choix éclairés',
    makeChoicesDescription:
        'Un plan de validation qui assure que votre ado s’engage à distinguer la réalité de ses perceptions et donne suite à ses questionnements. Un large éventail de facteurs à considérer et des propositions de pistes de solution lui permettront de prendre des décisions éclairées qui viennent de lui.',
    orientationTitle: 'S’orienter autrement',
    orientationDescription:
        'Un environnement d’épanouissement virtuel qui parle à votre ado. Une approche autonome qui considère tous les facteurs clés du processus d’orientation en 16 heures. Accessible de partout, en tout temps.',
    wellSurroundedTitle: 'Bien s’entourer, c’est la clé',
    wellSurroundedDescription:
        "Des mises en situation, des appels à la discussion et l’identification d'individus clés dans l’atteinte de ses objectifs vont l’éveiller à l’importance d’adopter de saines habitudes de communication en ligne et hors-ligne et d'entretenir son réseau de contacts.",
    haveDiscussionTitle: 'Amorcer des discussions d’avenir',
    haveDiscussionDescription:
        'Favoriser les échanges constructifs avec votre ado, à son rythme, grâce aux cartes d’accompagnement. Utile pour évoquer de nouvelles pistes de réflexion et l’encourager.',
    supportedBy: 'Réalisé grâce à leur support',
    supportOnLaRuche: 'Supportez-nous sur La Ruche !',
    supportOnLaRucheParagraph:
        'Notre campagne de sociofinancement est lancée. Procurez-vous un droit d’accès à Je mise sur moi en prévente pour soutenir notre initiative.',
    schoolRepTitle: 'Représentant d’une école\u00A0?',
    schoolRepDescription:
        'Intéressé à mieux préparer vos élèves au monde qui les attend ? Déployez Je mise sur moi au sein de votre école maintenant !',
    moreInfoTitle: "Besoin de plus d'informations\u00A0?",
    moreInfoDescription:
        'Pour de plus amples renseignements sur Je mise sur moi, rendez-vous à la boutique.',
    stayConnected: 'Restons connectés',
    emailDescription:
        'Inscrivez-vous ici afin d’être informé sur les avancements de notre équipe, nos nouveaux parcours virtuels, de la disponibilité de la version anglophone, etc.',
    informButton: 'Informez-moi',
}

export default jmsm
