const contribution = {
    title: 'Notre contribution',
    header: 'Notre contribution au collectif',
    description:
        'Nous avons un rêve, contribuer à ce que tous aient accès à une éducation. Ensemble, créons un monde où tous peuvent voir grand, où tous cultivent leurs passions en étant sur leur X.',
    ourDream: 'Notre rêve',
    ourDreamDescription:
        'Nous avons un rêve, contribuer à ce que tous aient accès à une éducation. Ensemble, créons un monde où tous peuvent voir grand, où tous cultivent leurs passions en étant sur leur X.',
    reality: 'Comment en faire une réalité',
    realityDescription:
        "Nous nous engageons à faire le don de certains de nos produits et services à divers organismes communautaires à but non lucratif. Nous réalisons des campagnes de sensibilisation afin de soutenir des causes qui nous tiennent à cœur. Nous offrons un répertoire de contenu éducationnel gratuit. Nous sommes ouverts à la collaboration avec tous individus et sociétés qui partagent notre rêve afin de réaliser des initiatives d'impacts qui pourront accompagner notre relève dans l'atteinte de leur plein potentiel.",
    contributions: 'Nos contributions à ce jour',
    dontDrink: "Don't drink and drive awareness campaign",
    antreJeune: "L'Antre-Jeunes de Mercier Est",
    antreJeuneDescription:
        "Nous avons fait le don de notre produit « Je mise sur moi » à l'ensemble des jeunes supportés par l'Antre-Jeunes de Mercier Est. Entre autres, l'organisme offre accès à des jeunes issus de milieux défavorisés à leurs deux établissements où ils peuvent participer à diverses activités d'enrichissement accompagnés par des intervenants et animateurs, accéder à divers équipements et participer à des événements et opportunités d'apprentissage offertes généreusement par leur équipe.",
    antreJeuneButton: "L'Antre-Jeunes",
    harmony: 'Projet Harmonie',
    harmonyDescription:
        "Nous avons fait le don de notre produit « Je mise sur moi » à l'ensemble des jeunes supportés par le Projet Harmonie. L’organisme a comme mission de créer un milieu de vie accueillant et intégré à la communauté pour les résident.es du HLM La Pépinière (Programme de logement sans but lucratif - HLM) afin de les accompagner dans le développement de leur plein potentiel et a comme vision de faire de leur milieu de vie un modèle d'inspiration pour tous en favorisant l'épanouissement personnel et collectif.",
    support: 'Supportez-nous',
    supportDescription:
        'Pour nous encourager dans nos initiatives communautaires, nous permettre de créer davantage de contenus pour notre répertoire éducationnel gratuit, nous donner les ressources nécessaires à la réalisation de campagnes de sensibilisation à fort impact ou encore nous encourager à poursuivre la donation de nos produits et services à divers organismes à but non lucratif, nous vous invitons à vous abonner à notre Patreon où tous les fonds récoltés nous permettront de continuer toutes ces initiatives.',
    supportButton: 'Supporter Extrapole',
}

export default contribution
