const thankYou = {
    title: 'Merci !',
    header: 'Merci !',
    description: 'Nous prendrons contact avec vous bientôt.',
    sent: 'C’est envoyé !',
    contactingUs:
        'Merci de nous avoir contactés. Nous allons prendre connaissance de votre demande et allons vous revenir dans les plus brefs délais.',
}

export default thankYou
