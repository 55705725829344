const contact = {
    title: 'Contact',
    submit: 'Soumettre',
    previewTitlePart1: 'Vous avez ',
    previewTitlePart2: 'des questions, des idées\u00A0?',
    hereForYou: 'Nous sommes là pour vous.',
    letUsKnow:
        'Écrivez-nous vos idées, questions et recommandations. Ce sera avec plaisir que nous vous répondrons.',
    contactEmail: 'info@extrapole.ca',
    coordinates: 'Coordonnées',
    addressLine1: '2135 Sherbrooke Est',
    addressLine2: 'Montréal, Québec',
    addressLine3: 'H2K 1C2',
    contactFormCardTitle: 'Formulaire',
    firstName: 'Prénom',
    lastName: 'Nom',
    phone: 'Téléphone',
    ext: 'Ext.',
    enterprise: 'Entreprise',
    howCanWeHelp: 'Comment pouvons-nous vous aider\u00A0?',
}

export default contact
