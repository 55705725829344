const contact = {
    title: 'Contact',
    submit: 'Submit',
    previewTitlePart1: 'Do you have ',
    previewTitlePart2: 'any questions or ideas\u00A0?',
    hereForYou: 'We are here for you.',
    letUsKnow:
        'Let us know your ideas, questions and recommendations. It will be our pleasure to follow up with you.',
    contactEmail: 'info@extrapole.ca',
    coordinates: 'Coordinates',
    addressLine1: '2135 Sherbrooke Est',
    addressLine2: 'Montréal, Québec',
    addressLine3: 'H2K 1C2',
    contactFormCardTitle: 'Form',
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Phone',
    ext: 'Ext.',
    enterprise: 'Enterprise',
    howCanWeHelp: 'How can we help you\u00A0?',
}

export default contact
